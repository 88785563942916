import { useEffect, useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { LoadingScreen } from "../common/LoadingScreen"
import { TopPageHeader } from "../header/TopPageHeader"
import { Link } from "react-router-dom";
import { RandomFighterPicker } from "./RandomFighterPicker";
import { axiosInstance } from "../../configs/axios";
import { PlayerOmakaseTierTable } from "../playerpage/componnts/omakaseTierTable/PlayerOmakaseTierTable";
import { Footer } from "../footer/Footer";
import { Alert, AlertTitle } from "@mui/material";
import { ScreenshotButton } from "../playerpage/ScreenshotButton";

export const OmakasePage = () => {

  const [isLoading, setIsLoading] = useState(true)

  const { user, beingLoggedIn, setBeingLoggedIn } = useAuthContext();

  const [omakaseTierLists, setOmakaseTierLists] = useState([])


  useEffect(() => {
    window.scrollTo({
      top: 0
    });

    if (user == null && !beingLoggedIn) {
      // １.5秒待たないとuserが取得できてもLoadingを閉じてしまう
      setTimeout(() => setIsLoading(false), 1500)
      setOmakaseTierLists([])
      return
    }
    setIsLoading(true)
    Promise.all([
      axiosInstance.get(`/userinfo?userid=${user?.id}`)
    ]).then(([userInfoResponse]) => {
      const userInfo = userInfoResponse.data
      const newTierLists = JSON.parse(userInfo.omakaseTier).filter((tierList) => { return tierList.characters.length !== 0 || tierList.id === 0 })

      newTierLists.forEach((tierList) => {
        tierList.characters.forEach((character) => {
          character.isUsed = false;
        });
      });

      setOmakaseTierLists(newTierLists)

      // 1秒待たないとSliderの初期値が反映しきれない（アニメーションが入る）
      setTimeout(() => setIsLoading(false), 1000)
    })
  }, [user])

  return (
    <div style={{
      width: "100%",
      maxWidth: "600px",
      margin: "0px auto",
      padding: "20px"
    }}>
      {(isLoading || beingLoggedIn) && <LoadingScreen />}
      <TopPageHeader user={user} beingLoggedIn={beingLoggedIn} setBeingLoggedIn={setBeingLoggedIn} />
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div style={{ display: "flex" }}>
          <Link className="link" to="/" style={{ fontSize: "16px", fontWeight: "bold", textDecoration: "none" }} state={{ test: "aa" }}>
            トップページ
          </Link>
          {user &&
            <p style={{ display: "block", fontSize: "14px", fontWeight: "bold", margin: "auto 5px" }}>
              ＞
            </p>
          }
          {user &&
            <Link className="link" to={`/playerpage?userid=${user?.id}`} style={{ fontSize: "16px", fontWeight: "bold", textDecoration: "none" }}>
              マイページ
            </Link>
          }
        </div>

      </div>
      {!user &&
        <Alert sx={{ fontSize: "12px", borderRadius: "10px", margin: "10px" }} severity="error">
          <AlertTitle sx={{ fontSize: "12px", fontWeight: "bold" }}>ログインが必要です
          </AlertTitle>
          おまかせで遊ぶためにはログインが必要です。
        </Alert>
      }
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "20px", alignItems: "flex-end" }}>
        <p style={{ display: "block", fontSize: "18px", fontWeight: "bold" }}>おまかせで遊ぶ</p>
      </div>
      <hr style={{ border: "none", height: "2px", background: "black", margin: "0px" }} />

      <RandomFighterPicker tierLists={omakaseTierLists} setTierLists={setOmakaseTierLists} />

      {user &&
        <div>
          <div>
            <p style={{ display: "block", fontSize: "18px", fontWeight: "bold" }}>おまかせランク</p>
            <hr style={{ border: "none", height: "2px", background: "black", margin: "0px" }} />
          </div>


          <PlayerOmakaseTierTable tierLists={omakaseTierLists} setTierLists={setOmakaseTierLists} isLoggedInUser={true} />
          <div style={{ display: "flex", justifyContent: "end" }}>
            <ScreenshotButton type={"omakase-tier-table"} />
          </div>

          <div style={{
            display: "flex",
            flexDirection: "column",
            justifyItems: "center",
            alignItems: "center"
          }}>
            <Link className="blackButton" to="/mypage" style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "35px",
              width: "120px",
              color: "white",
              background: "black",
              fontSize: "12px",
              fontWeight: "bold",
              border: "none",
              borderRadius: "10px",
              textDecoration: "none",
              margin: "20px"
            }}>マイページを編集</Link>
          </div>
        </div>}

      <Footer />
    </div>
  )
}