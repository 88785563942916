export const initialOmakaseTier = [
  { "id": "S", "text": "Sランク", "characters": [] },
  { "id": "A", "text": "Aランク", "characters": [] },
  { "id": "B", "text": "Bランク", "characters": [] },
  { "id": "C", "text": "Cランク", "characters": [] },
  {
    "id": "D", "text": "Dランク", "characters": [
      { "id": 1, "characterName": "マリオ" },
      { "id": 2, "characterName": "ドンキーコング" },
      { "id": 3, "characterName": "リンク" },
      { "id": 4, "characterName": "サムス" },
      { "id": 5, "characterName": "ダークサムス" },
      { "id": 6, "characterName": "ヨッシー" },
      { "id": 7, "characterName": "カービィ" },
      { "id": 8, "characterName": "フォックス" },
      { "id": 9, "characterName": "ピカチュウ" },
      { "id": 10, "characterName": "ルイージ" },
      { "id": 11, "characterName": "ネス" },
      { "id": 12, "characterName": "キャプテン・ファルコン" },
      { "id": 13, "characterName": "プリン" },
      { "id": 14, "characterName": "ピーチ" },
      { "id": 15, "characterName": "デイジー" },
      { "id": 16, "characterName": "クッパ" },
      { "id": 17, "characterName": "アイスクライマー" },
      { "id": 18, "characterName": "シーク" },
      { "id": 19, "characterName": "ゼルダ" },
      { "id": 20, "characterName": "ドクターマリオ" },
      { "id": 21, "characterName": "ピチュー" },
      { "id": 22, "characterName": "ファルコ" },
      { "id": 23, "characterName": "マルス" },
      { "id": 24, "characterName": "ルキナ" },
      { "id": 25, "characterName": "こどもリンク" },
      { "id": 26, "characterName": "ガノンドロフ" },
      { "id": 27, "characterName": "ミュウツー" },
      { "id": 28, "characterName": "ロイ" },
      { "id": 29, "characterName": "クロム" },
      { "id": 30, "characterName": "Mr.ゲーム＆ウォッチ" },
      { "id": 31, "characterName": "メタナイト" },
      { "id": 32, "characterName": "ピット" },
      { "id": 33, "characterName": "ブラックピット" },
      { "id": 34, "characterName": "ゼロスーツサムス" },
      { "id": 35, "characterName": "ワリオ" },
      { "id": 36, "characterName": "スネーク" },
      { "id": 37, "characterName": "アイク" },
      { "id": 38, "characterName": "ポケモントレーナー" },
      { "id": 39, "characterName": "ディディーコング" },
      { "id": 40, "characterName": "リュカ" },
      { "id": 41, "characterName": "ソニック" },
      { "id": 42, "characterName": "デデデ" },
      { "id": 43, "characterName": "ピクミン＆オリマー" },
      { "id": 44, "characterName": "ルカリオ" },
      { "id": 45, "characterName": "ロボット" },
      { "id": 46, "characterName": "トゥーンリンク" },
      { "id": 47, "characterName": "ウルフ" },
      { "id": 48, "characterName": "むらびと" },
      { "id": 49, "characterName": "ロックマン" },
      { "id": 50, "characterName": "Wii Fit トレーナー" },
      { "id": 51, "characterName": "ロゼッタ＆チコ" },
      { "id": 52, "characterName": "リトル・マック" },
      { "id": 53, "characterName": "ゲッコウガ" },
      { "id": 54, "characterName": "パルテナ" },
      { "id": 55, "characterName": "パックマン" },
      { "id": 56, "characterName": "ルフレ" },
      { "id": 57, "characterName": "シュルク" },
      { "id": 58, "characterName": "クッパJr." },
      { "id": 59, "characterName": "ダックハント" },
      { "id": 60, "characterName": "リュウ" },
      { "id": 61, "characterName": "ケン" },
      { "id": 62, "characterName": "クラウド" },
      { "id": 63, "characterName": "カムイ" },
      { "id": 64, "characterName": "ベヨネッタ" },
      { "id": 65, "characterName": "インクリング" },
      { "id": 66, "characterName": "リドリー" },
      { "id": 67, "characterName": "シモン" },
      { "id": 68, "characterName": "リヒター" },
      { "id": 69, "characterName": "キングクルール" },
      { "id": 70, "characterName": "しずえ" },
      { "id": 71, "characterName": "ガオガエン" },
      { "id": 72, "characterName": "パックンフラワー" },
      { "id": 73, "characterName": "ジョーカー" },
      { "id": 74, "characterName": "勇者" },
      { "id": 75, "characterName": "バンジョー＆カズーイ" },
      { "id": 76, "characterName": "テリー" },
      { "id": 77, "characterName": "ベレトス" },
      { "id": 78, "characterName": "ミェンミェン" },
      { "id": 79, "characterName": "スティーブ" },
      { "id": 80, "characterName": "セフィロス" },
      { "id": 81, "characterName": "ホムヒカ" },
      { "id": 82, "characterName": "カズヤ" },
      { "id": 83, "characterName": "ソラ" },
      { "id": 84, "characterName": "Mii 格闘タイプ" },
      { "id": 85, "characterName": "Mii 剣術タイプ" },
      { "id": 86, "characterName": "Mii 射撃タイプ" }]
  }, { "id": "0", "text": "", "characters": [] }]