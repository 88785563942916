import { PlayerOmakaseTierList } from "../../playerpage/componnts/omakaseTierTable/PlayerOmakaseTierList"

export const SavedGuestOmakaseTierTable = ({ tierLists }) => {
  return (
    <>
      <div id='omakase-tier-table'>
        {tierLists.map((tierList) => {
          return <PlayerOmakaseTierList key={tierList.id} id={tierList.id} characters={tierList.characters} tierList={tierList} />
        })}
      </div>
    </>
  )
}