export const CharacterImg = ({ characterName, character }) => {
  return (
    <div
      style={{
        background: character?.isUsed ? "rgba(245,247,249,1.00)" : "rgba(245,247,249,1.00)",
        width: "50px",
        height: "50px",
        boxShadow: "rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px",
        borderRadius: "8px",
        position: "relative",
      }}
    >
      <img
        src={"/images/characters/" + characterName + ".png"}
        alt={`${characterName}`}
        style={{
          borderRadius: "8px",
          width: "50px",
          objectFit: "cover",
          WebkitTouchCallout: 'none',
          filter: character?.isUsed ? "grayscale(100%)" : "none", // グレースケール
          opacity: character?.isUsed ? 0.5 : 1, // 不透明度を下げる
        }}
      />
      {character?.isUsed && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // 黒いオーバーレイ
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontSize: "20px",
            fontWeight: "bold",
            pointerEvents: "none",
          }}
        >
        </div>
      )}
    </div>
  );
};
