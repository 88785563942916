import { useEffect, useState } from "react";
import { Footer } from "../../footer/Footer";
import { Link } from "react-router-dom";
import { initialOmakaseTier } from "./initialOmakaseTier";
import { Header } from "../../header/Header";
import { RandomFighterPicker } from "../RandomFighterPicker";
import { GuestOmakaseTierTable } from "./GuestOmakaseTierTable";
import { LoadingScreen } from "../../common/LoadingScreen";
import { SavedGuestOmakaseTierTable } from "./SavedGuestOmakaseTierTable";

export const OmakaseGuestPage = () => {

  const [isLoading, setIsLoading] = useState(true)

  const [editingOmakaseTierLists, setEditingOmakaseTierLists] = useState(initialOmakaseTier)

  const [savedOmakaseTierLists, setSavedOmakaseTierLists] = useState(initialOmakaseTier)

  const [isSaved, setIsSaved] = useState(false)

  useEffect(() => {
    window.scrollTo({
      top: 0
    })

    const storageOmakaseTier = JSON.parse(localStorage.getItem("initialOmakaseTier"))
    
    if (storageOmakaseTier) {
      const [storageOmakaseTier1, storageOmakaseTier2] = storageOmakaseTier
      setEditingOmakaseTierLists(storageOmakaseTier1)
      setSavedOmakaseTierLists(storageOmakaseTier2)
    } else {
      setEditingOmakaseTierLists(initialOmakaseTier)
      setSavedOmakaseTierLists(initialOmakaseTier)
    }

    setIsLoading(true)
    setTimeout(() => setIsLoading(false), 1000)
  }, [])

  const handleOnSave = () => {
    const newTierLists = editingOmakaseTierLists.filter((tierList) => { return tierList.characters.length !== 0 || tierList.id === 0 })
    newTierLists.forEach((tierList) => {
      tierList.characters.forEach((character) => {
        character.isUsed = false;
      });
    });

    setSavedOmakaseTierLists(newTierLists)
    localStorage.setItem("initialOmakaseTier", JSON.stringify([editingOmakaseTierLists, savedOmakaseTierLists]))
    setIsSaved(true)
  }

  return (
    <div style={{
      width: "100%",
      maxWidth: "600px",
      margin: "0px auto",
      padding: "20px"
    }}>
      {(isLoading) && <LoadingScreen />}
      <Header />
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div style={{ display: "flex" }}>
          <Link className="link" to="/" style={{ fontSize: "16px", fontWeight: "bold", textDecoration: "none" }} state={{ test: "aa" }}>
            トップページ
          </Link>
        </div>

      </div>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "20px", alignItems: "flex-end" }}>
        <p style={{ display: "block", fontSize: "18px", fontWeight: "bold" }}>おまかせで遊ぶ</p>
      </div>
      <hr style={{ border: "none", height: "2px", background: "black", margin: "0px" }} />

      <RandomFighterPicker tierLists={savedOmakaseTierLists} setTierLists={setSavedOmakaseTierLists} isSaved={isSaved} />
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "20px", alignItems: "flex-end" }}>
        <p style={{ display: "block", fontSize: "18px", fontWeight: "bold" }}>おまかせランク</p>
      </div>
      <hr style={{ border: "none", height: "2px", background: "black", margin: "0px" }} />
      <div>
        {!isSaved ?
          <GuestOmakaseTierTable tierLists={editingOmakaseTierLists} setTierLists={setEditingOmakaseTierLists} />
          : <SavedGuestOmakaseTierTable tierLists={savedOmakaseTierLists} />}
      </div>
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyItems: "center",
        alignItems: "center"
      }}>
        {!isSaved ?
          <button className="blackButton" onClick={handleOnSave}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "35px",
              width: "120px",
              color: "white",
              background: "black",
              fontSize: "12px",
              fontWeight: "bold",
              border: "none",
              borderRadius: "10px",
              textDecoration: "none",
              margin: "20px"
            }}>確定する</button>
          :
          <button className="blackButton" onClick={() => setIsSaved(false)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "35px",
              width: "120px",
              color: "white",
              background: "black",
              fontSize: "12px",
              fontWeight: "bold",
              border: "none",
              borderRadius: "10px",
              textDecoration: "none",
              margin: "20px"
            }}>編集する</button>

        }
      </div>
      <Footer />
    </div>
  )
}