import { DndContext, DragOverlay } from "@dnd-kit/core"
import { CharacterPool } from "./CharacterPool"
import { Character } from "../Character"
import { OmakaseTierList } from "./OmakaseTierList"
import { useTierListsDnd } from "../../hooks/useTierListsDnd"

export const MyOmakaseTierTable = ({ tierLists, setTierLists }) => {

  const { sensors, pointerWithin, handleDragStart, handleDragEnd, handleDragOver, handleDragCancel, activeCharacter } = useTierListsDnd({ tierLists, setTierLists })

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "20px", alignItems: "flex-end" }}>
        <p style={{ display: "block", fontSize: "18px", fontWeight: "bold" }}>おまかせランク</p>
      </div>
      <hr style={{ border: "none", height: "2px", background: "black", margin: "0px" }} />

      <DndContext sensors={sensors} collisionDetection={pointerWithin} onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragOver={handleDragOver} onDragCancel={handleDragCancel}>
        <div>
          {tierLists.map((tierList) => {
            // omakaseTierはidが文字列のため
            if (tierList.id === "0") {
              return null
            }
            return <OmakaseTierList key={tierList.id} id={tierList.id} characters={tierList.characters} tierList={tierList} ></OmakaseTierList>
          })}
          {tierLists.map((tierList) => {
            if (tierList.id === "0") {
              return <CharacterPool key={tierList.id} id={tierList.id} rank={tierList.rank} characters={tierList.characters} />
            }
            return null
          })}
        </div>

        <DragOverlay style={{ transformOrigin: "0 0 " }}>
          {activeCharacter ? <Character id={activeCharacter.id} characterName={activeCharacter.characterName} /> : null}
        </DragOverlay>
      </DndContext>
    </>
  )
}