import './App.css';
import { Home } from './components/home/Home'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { PlayerDetail } from './components/playerpage/PlayerDetail';
import { MyDetail } from './components/mypage/MyDetail';
import { TermsAndServicePage } from './components/policy/TermsAndServicePage';
import { useAuthContext } from './hooks/useAuthContext';
import { QAPage } from './components/qa/QAPage';
import { OmakasePage } from './components/omakase/OmakasePage';
import { usePageTracking } from './hooks/usePageTracking';
import { OmakaseGuestPage } from './components/omakase/guest/OmakaseGuestPage';

function App() {

  const { user } = useAuthContext();

  return (
    <Router>
      <PageTrackingWrapper/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mypage" element={user ? <MyDetail /> : <Navigate to="/" replace />} />
        <Route path="/playerpage" element={<PlayerDetail />} />
        <Route path="/omakase" element={<OmakasePage />} />
        <Route path="/omakaseguest" element={<OmakaseGuestPage />} />
        <Route path="/terms" element={<TermsAndServicePage />} />
        <Route path="/qa" element={<QAPage />} />
      </Routes>
    </Router>
  );
}

export default App;

const PageTrackingWrapper = () => {
  usePageTracking();
  return null;
};
