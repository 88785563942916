import { useEffect } from "react";
import { axiosInstance } from "../../../configs/axios";
import { rankMap } from "../../data/RankMap";
import { charactersMap } from "../../data/CharactersMap";
import { requestMap } from "../../data/RequestMap";
import { TierListData } from "../../data/TierListData";
import { requestData } from "../../data/requestData";

export const usePlayerPageInfo = ({ userId, state, setPlayer, setTierLists, setRequestTierLists, setOmakaseTierLists, setIsLoading }) => {
  useEffect(() => {
    axiosInstance.post("/user", userId, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      const data = response.data
      if (state && data) {
        data.bitCalendar = state.bitCalendar
      }
      setPlayer(data)
    })

    if (!state) {
      Promise.all([
        axiosInstance.get(`/tier?userid=${userId}`),
        axiosInstance.get(`/requesttier?userid=${userId}`),
        axiosInstance.get(`/userinfo?userid=${userId}`)
      ]).then(([
        tierResponse,
        requestTierResponse,
        userInfoResponse
      ]) => {
        const tierListMap = new Map()
        tierResponse.data.forEach((tier) => {
          tierListMap.set(tier.id,
            {
              id: tier.id,
              rate: rankMap.get(tier.id).rate,
              rank: rankMap.get(tier.id).rank,
              characters: tier.characterIdList.map(id => charactersMap.get(id)),
            })
        });

        const requestTierListMap = new Map()
        requestTierResponse.data.forEach((request) => {
          requestTierListMap.set(request.id,
            {
              id: request.id,
              text: requestMap.get(request.id).text,
              characters: request.characterIdList.map(id => charactersMap.get(id)),
            })
        });

        setTierLists(() =>
          TierListData.map((tierList) => {
            if (tierListMap.has(tierList.id)) {
              return tierListMap.get(tierList.id)
            } else {
              tierList.characters = []
              return tierList
            }
          })
            .filter((tierList) => { return tierList.id !== 0 })
            .filter((tierList) => { return tierList.characters.length !== 0 })
        );

        setRequestTierLists(() =>
          requestData.map((request) => {
            if (requestTierListMap.has(request.id)) {
              return requestTierListMap.get(request.id)
            } else {
              request.characters = []
              return request
            }
          })
            .filter((request) => { return request.id !== 0 })
            .filter((requestTierList) => { return requestTierList.characters.length !== 0 })
        );

        const userInfo = userInfoResponse.data
        setOmakaseTierLists(
          JSON.parse(userInfo.omakaseTier)
            .filter((tierList) => { return tierList.id !== "0" })
            .filter((tierList) => { return tierList.characters.length !== 0 })
        )

        setIsLoading(false)
      })
    }

    if (state) {
      setPlayer(() => {
        const newPlayer = state.user
        newPlayer.bitCalendar = state.bitCalendar
        return newPlayer
      })
      setTierLists(
        state.tierLists
          .filter((tierList) => { return tierList.id !== 0 })
          .filter((tierList) => { return tierList.characters.length !== 0 })
      )
      setRequestTierLists(
        state.requestTierLists
          .filter((requestTierList) => { return requestTierList.id !== 0 })
          .filter((requestTierList) => { return requestTierList.characters.length !== 0 })
      )
      setOmakaseTierLists(
        state.omakaseTierLists
          .filter((tierList) => { return tierList.id !== "0" })
          .filter((tierList) => { return tierList.characters.length !== 0 })
      )
      setIsLoading(false)
    }
  }, []);
}