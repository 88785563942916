import { PlayerOmakaseTierList } from "./PlayerOmakaseTierList"

export const PlayerOmakaseTierTable = ({ tierLists }) => {
  return (
    <>
      <div id='omakase-tier-table'>
        {tierLists.map((tierList) => {
          return <PlayerOmakaseTierList key={tierList.id} id={tierList.id} characters={tierList.characters} tierList={tierList} />
        })}
      </div>
    </>
  )
}