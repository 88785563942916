import { Link } from "react-router-dom"
import { CharacterImg } from "./CharacterImg"
import { charactersMap } from "../data/CharactersMap"
import { Rank } from "./modal/Rank"
import { BlankImg } from "./BlankImg"

import "../../styles/playerPlate.css"



export const PlayerPlate = ({ player }) => {

  return (
    <>
      <Link className="playerPlate" to={`/playerpage?userid=${player?.userId}`} style={{
        display: "block",
        textDecoration: 'none',
        background: "rgb(60, 60, 60)",
        width: "100%",
        margin: "15px auto",
        borderRadius: "15px",
        paddingRight: "10px",
        paddingBottom: "20px",
        WebkitTouchCallout: 'none',//実験的
      }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center",padding: "15px 15px 5px" }}>
          <Link to={`/playerpage?userid=${player?.userId}`} style={{ textDecoration: 'none' }}>
            <div style={{ display: "flex", alignItems: "center"}}>
              <p style={{
                display: "flex",
                color: "white",
                fontSize: "18px",
                padding: "0px",
                fontWeight: "bold",
                alignItems: "center"
              }}>{player?.name}
              </p>
              {player?.postLink && <img src="images/登録済み.png" alt="登録済み" style={{ width: "22px", objectFit: "contain", WebkitTouchCallout: 'none', margin : "10px" }} />}
            </div>
          </Link>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src="images/ハート.png" alt="ハート" style={{ width: "17px", objectFit: "contain", WebkitTouchCallout: 'none', margin : "10px" }} />
            <p style={{
              display: "flex",
              color: "white",
              fontSize: "18px",
              alignItems: "center"
            }}>{player?.likes}</p>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", paddingRight: "20px" }}>
          <div>
            <p style={{
              color: "white",
              fontSize: "12px",
              padding: "10px 20px",
              fontWeight: "bold"
            }}>ヒットしたファイター</p>
            <div style={{ marginLeft: "30px" }}>
              {player?.selectedChar ? <CharacterImg characterName={charactersMap.get(player?.selectedChar)?.characterName} /> : <BlankImg />}
            </div>

          </div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <p style={{
              color: "white",
              fontSize: "12px",
              padding: "10px 20px",
              fontWeight: "bold"
            }}>ランク</p>
            {player?.strength ? <Rank id={player?.strength}></Rank> : <BlankImg />}
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", paddingRight: "8px" }}>
          <div>
            <p style={{
              color: "white",
              fontSize: "12px",
              padding: "20px 20px 10px",
              fontWeight: "bold"
            }}>メインファイター</p>
            <div style={{ display: "flex", columnGap: "10px", marginLeft: "30px" }}>
              {player?.char1 ? <CharacterImg characterName={charactersMap.get(player?.char1)?.characterName} /> : <BlankImg />}
              {player?.char2 ? <CharacterImg characterName={charactersMap.get(player?.char2)?.characterName} /> : <BlankImg />}
              {player?.char3 ? <CharacterImg characterName={charactersMap.get(player?.char3)?.characterName} /> : <BlankImg />}
            </div>

          </div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <p style={{
              color: "white",
              fontSize: "12px",
              padding: "20px 20px 10px",
              fontWeight: "bold"
            }}>最高ランク</p>
            {player?.bestRank ? <Rank id={player?.bestRank}></Rank> : <BlankImg />}
          </div>
        </div>

      </Link>
    </>
  )
}
