import { Link } from "react-router-dom"

import "../../styles/blackButton.css"

export const MyPageHeader = ({ handleClieckSaveButton, onOpen,}) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
      <Link to={"/"} style={{ display: "flex", width: "50%", maxWidth: "190px", height: "80px", }}>
        <img
          src={"images/ロゴ.png"}
          alt="ロゴ"
          style={{
            width: "100%",
            objectFit: "contain",
            margin: "auto 10px",
          }} />
      </Link>

      <div style={{ display: "flex", flexDirection: "column", width: "120px" }}>
      <button onClick={handleClieckSaveButton} 
            className="blackButton"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "35px",
              width: "120px",
              color: "white",
              background: "black",
              fontSize: "12px",
              fontWeight: "bold",
              border: "none",
              borderRadius: "10px",
              textDecoration: "none",
              margin: "5px"
            }}>マイページの保存</button>
        <button className="blackButton"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "35px",
            width: "120px",
            color: "white",
            background: "black",
            fontSize: "12px",
            fontWeight: "bold",
            border: "none",
            borderRadius: "10px",
            textDecoration: "none",
            padding: "10px",
            cursor: "pointer",
            margin: "5px"
          }}
          onClick={() => onOpen('registerPost')}>
          <img src="images/X.jpg" alt="X" style={{ width: "20px", objectFit: "cover", marginRight: "5px" }} />ポストの登録
        </button>
      </div>
    </div>
  )
}