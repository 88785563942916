import { Link } from "react-router-dom"

export const Footer = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
      <Link className="link" to={'/'} style={{ margin: "0px auto" }}>
        <img
          src={"/images/ロゴ.png"}
          alt="ロゴ"
          style={{
            width: "80px",
            objectFit: "contain",
            margin: "0px auto",
            marginTop: "10px",
            cursor: "pointer"
          }} />
      </Link>
      <p style={{ fontSize: "10px", fontWeight: "bold", margin: "0px 0px", marginBottom: "5px" }}>Ver.2.3.6</p>
      <dev style={{ display: "flex", margin: "0px auto" }}>
        <Link className="link" to={"/terms"} style={{ fontSize: "10px", fontWeight: "bold", margin: "5px 5px", marginBottom: "5px" }}>利用規約</Link>
        <Link className="link" to={"/qa"} style={{ fontSize: "10px", fontWeight: "bold", margin: "5px 5px", marginBottom: "5px" }}>Q&A</Link>
      </dev>
      <p style={{ fontSize: "8px", fontWeight: "bold", textAlign: "center" }}>大乱闘スマッシュブラザーズは<br />
        任天堂株式会社様の登録商標であり<br />当サイトは個人が運営する非公式のWebサービスです。<br />任天堂株式会社様、他関連企業様とは一切関係ありません。</p>
    </div>
  )
}