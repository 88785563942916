import { Link } from "react-router-dom"

export const Header = () => {
  return (
    <Link to={"/"} style={{ display: "flex", width: "50%", maxWidth: "190px", height: "80px", }}>
      <img
        src={"/images/ロゴ.png"}
        alt="ロゴ"
        style={{
          width: "100%",
          objectFit: "contain",
          margin: "auto 10px",
        }} />
    </Link>
  )
}