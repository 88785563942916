import { DndContext, DragOverlay } from "@dnd-kit/core"
import { useTierListsDnd } from "../../mypage/hooks/useTierListsDnd"
import { OmakaseTierList } from "../../mypage/components/omakaseTierTable/OmakaseTierList"
import { CharacterPool } from "../../mypage/components/omakaseTierTable/CharacterPool"
import { Character } from "../../mypage/components/Character"


export const GuestOmakaseTierTable = ({ tierLists, setTierLists }) => {

  const { sensors, pointerWithin, handleDragStart, handleDragEnd, handleDragOver, handleDragCancel, activeCharacter } = useTierListsDnd({ tierLists, setTierLists })

  return (
    <>
      <DndContext sensors={sensors} collisionDetection={pointerWithin} onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragOver={handleDragOver} onDragCancel={handleDragCancel}>
        <div>
          {tierLists.map((tierList) => {
            // omakaseTierはidが文字列のため
            if (tierList.id === "0") {
              return null
            }
            return <OmakaseTierList key={tierList.id} id={tierList.id} characters={tierList.characters} tierList={tierList} ></OmakaseTierList>
          })}
          {tierLists.map((tierList) => {
            if (tierList.id === "0") {
              return <CharacterPool key={tierList.id} id={tierList.id} rank={tierList.rank} characters={tierList.characters} />
            }
            return null
          })}
        </div>

        <DragOverlay style={{ transformOrigin: "0 0 " }}>
          {activeCharacter ? <Character id={activeCharacter.id} characterName={activeCharacter.characterName} /> : null}
        </DragOverlay>
      </DndContext>
    </>
  )
}