import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import { useDroppable } from "@dnd-kit/core";
import { Character } from "../Character";
import { TierListData } from "../../../data/TierListData";
import { rankMap } from "../../../data/RankMap";

export const TierList = ({ id, characters, tierList, tierLists, setTierLists }) => {
  const { setNodeRef } = useDroppable({ id: id })

  const higherTierId = tierList.id + 100

  const lowerTierId = tierList.id - 100

  const havingCharacters = () => {
    return characters.length !== 0 || tierLists.length <= 2
  }

  const checkHigherTier = () => {
    if (!rankMap.has(higherTierId)) { return false }
    return !tierLists.some(tierList => tierList.id === higherTierId);
  }

  const checkLowerTier = () => {
    if (!rankMap.has(lowerTierId)) { return false }
    return !tierLists.some(tierList => tierList.id === lowerTierId);
  }

  const handleHigherClick = () => {
    const higherTierList = TierListData.find((tld) => tld.id === higherTierId)
    setTierLists((prev) => [prev[0], ...([...(prev.slice(1)), higherTierList].sort((a, b) => b.id - a.id))])
  }

  const handleLowerClick = () => {
    const lowerTierList = TierListData.find((tld) => tld.id === lowerTierId)
    setTierLists((prev) => [prev[0], ...([...(prev.slice(1)), lowerTierList].sort((a, b) => b.id - a.id))])
  }

  const handleDeleteClick = () => {
    setTierLists(prev => prev.filter(tier => tier.id !== id))
  }

  return (
    <>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
        {checkHigherTier() && <button onClick={handleHigherClick} style={{ display: "flex", justifySelf: "center", padding: "0px", background: "none", border: "none", gridColumn: "2", width: "25px", height: "25px", marginTop: "15px" }}><img src="images/プラス.png" alt="追加" style={{ width: "25px", contain: "cover" }} /></button>}
        {!havingCharacters() && <button onClick={handleDeleteClick} style={{ display: "flex", justifySelf: "end", padding: "0px", background: "none", border: "none", gridColumn: "3", width: "25px", height: "25px", marginRight: "20px", marginTop: "15px" }}><img src="images/マイナス.png" alt="追加" style={{ width: "25px", contain: "cover" }} /></button>}
      </div>
      <SortableContext id={id} items={characters} strategy={rectSortingStrategy}>
        <div style={{ flex: "block", maxWidth: "800px", width: "100%", borderRadius: "10px", overflow: "hidden", margin: "10px auto" }}>
          <div style={{ position: "relative", width: "100%", height: "30px" }}>
            <img src="images/ティアバー.png" alt="ティアバー" style={{ width: "100%", height: "100%", objectFit: "fill" }} />
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
              {tierList.rate ? <p style={{ color: "white", fontSize: "16px", fontWeight: "bold", marginLeft: "20px" }}>スマメイト {tierList.rate}</p> : <p></p>}
              {tierList.rank ? <p style={{ color: "white", fontSize: "16px", fontWeight: "bold", marginRight: "20px" }}>クマメイト {tierList.rank}</p> : <p></p>}
            </div>
          </div>
          <div
            ref={setNodeRef}
            style={{
              width: "100%",
              background: "rgb(60, 60, 60)",
              textAlign: "center",
              verticalAlign: "middle",
              display: "flex"
            }}>

            <div style={{
              display: "grid",
              gridTemplateColumns: `repeat(auto-fill, minmax(50px,1fr))`,
              gridGap: 1,
              width: "100%",
              minHeight: "53px",
              margin: "10px 10px",
            }}>
              {characters.map((character) => (
                <Character key={character.id} id={character.id} characterName={character.characterName}></Character>
              ))}
            </div>
          </div>
        </div>
      </SortableContext>
      {checkLowerTier() && <button onClick={handleLowerClick} style={{ display: "flex", padding: "0px", margin: "0px auto 15px", background: "none", border: "none", width: "25px", height: "25px" }}><img src="images/プラス.png" alt="追加" style={{ width: "25px", contain: "cover" }} /></button>}
    </>
  )

}