import { useEffect } from "react";
import { axiosInstance } from "../../../configs/axios";
import { rankMap } from "../../data/RankMap";
import { charactersMap } from "../../data/CharactersMap";
import { requestMap } from "../../data/RequestMap";
import { TierListData } from "../../data/TierListData";
import { requestData } from "../../data/requestData";

export const useMyPageInfo = ({ user, setTierLists, setRequestTierLists, setOmakaseTierLists, setIsLoading }) => {
  useEffect(() => {
    Promise.all([
      axiosInstance.get(`/mytier?userid=${user?.id}`),
      axiosInstance.get(`/requesttier?userid=${user?.id}`),
      axiosInstance.get(`/userinfo?userid=${user?.id}`)
    ]).then(([tierResponse, requestTierResponse, userInfoResponse]) => {
      const tierListMap = new Map()
      tierResponse.data.forEach((tier) => {
        tierListMap.set(tier.id,
          {
            id: tier.id,
            rate: rankMap.get(tier.id).rate,
            rank: rankMap.get(tier.id).rank,
            characters: tier.characterIdList.map(id => charactersMap.get(id)),
          })
      });

      const requestTierListMap = new Map()
      requestTierResponse.data.forEach((request) => {
        requestTierListMap.set(request.id,
          {
            id: request.id,
            text: requestMap.get(request.id).text,
            characters: request.characterIdList.map(id => charactersMap.get(id)),
          })
      });

      setTierLists(() => {
        const newTierLists = TierListData.map((tierList) => {
          if (tierListMap.has(tierList.id)) {
            return tierListMap.get(tierList.id)
          } else {
            //TODO: TierListDataが参照型のためずれるが応急処置
            tierList.characters = []
            return tierList;
          }
        })
          // ファイターがいないティアは削除
          .filter((tierList) => { return tierList.characters.length !== 0 || tierList.id === 0 })

        if (checkEmptyTiers(newTierLists)) {
          const tierList_1500 = {
            id: 1500,
            rate: '1500',
            rank: '20',
            characters: []
          }

          return [newTierLists.find((tl) => tl.id === 0), tierList_1500]
        }
        return newTierLists
      });

      setRequestTierLists(() =>
        requestData.map((request) => {
          if (requestTierListMap.has(request.id)) {
            return requestTierListMap.get(request.id)
          } else {
            request.characters = []
            return request
          }
        }));
      
      const userInfo = userInfoResponse.data

      setOmakaseTierLists(JSON.parse(userInfo.omakaseTier))
      setIsLoading(false)
    })

  }, [user])
}

const checkEmptyTiers = (tiers) => {
  return tiers.every(tier => {
    if (tier.id !== 0) {
      return tier.characters.length === 0;
    }
    return true;
  });
}