import { CharacterImg } from "../../../home/CharacterImg";

export const TierList = ({ id, rank, characters, tierList }) => {
  return (
    <div id={id} items={characters} >
      <div style={{ flex: "block", maxWidth: "800px", width: "100%", borderRadius: "10px", overflow: "hidden", margin: "10px auto" }}>
        <div style={{ position: "relative", width: "100%", height: "30px" }}>
          <img src="images/ティアバー.png" alt="ティアバー" style={{ width: "100%", height: "100%", objectFit: "fill" }} />
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
            {tierList.rate ? <p style={{ color: "white", fontSize: "16px", fontWeight: "bold", marginLeft: "20px" }}>スマメイト {tierList.rate}</p> : <p></p>}
            {tierList.rank ? <p style={{ color: "white", fontSize: "16px", fontWeight: "bold", marginRight: "20px" }}>クマメイト {tierList.rank}</p> : <p></p>}
          </div>
        </div>
        <div style={{
          width: "100%",
          background: "rgb(60, 60, 60)",
          textAlign: "center",
          verticalAlign: "middle",
          display: "flex"
        }}>
          <div style={{
            display: "grid",
            gridTemplateColumns: `repeat(auto-fill, minmax(50px,1fr))`,
            gridGap: 1,
            width: "100%",
            minHeight: "53px",
            margin: "10px 10px",
          }}>
            {characters.map((character) => (
              <CharacterImg characterName={character.characterName}></CharacterImg>
            ))}
          </div>
        </div>
      </div>
    </div>
  )

}