import { DndContext, DragOverlay } from "@dnd-kit/core"
import { RequestTierList } from "./RequestTierList"
import { CharacterPool } from "./CharacterPool"
import { Character } from "../Character"
import { useTierListsDnd } from "../../hooks/useTierListsDnd"

export const MyRequestTierTable = ({ requestTierLists, setRequestTierLists }) => {

  const { sensors, pointerWithin, handleDragStart, handleDragEnd, handleDragOver, handleDragCancel, activeCharacter } = useTierListsDnd({ tierLists: requestTierLists, setTierLists: setRequestTierLists })

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "20px", alignItems: "flex-end" }}>
        <p style={{ display: "block", fontSize: "18px", fontWeight: "bold" }}>対戦したいファイター</p>
      </div>
      <hr style={{ border: "none", height: "2px", background: "black", margin: "0px" }} />

      <DndContext sensors={sensors} collisionDetection={pointerWithin} onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragOver={handleDragOver} onDragCancel={handleDragCancel}>
        <div>
          {requestTierLists.map((requestTierList) => {
            if (requestTierList.id === 0) {
              return null
            }
            return <RequestTierList key={requestTierList.id} id={requestTierList.id} characters={requestTierList.characters} requestTierList={requestTierList}></RequestTierList>
          })}
          {requestTierLists.map((requestTierList) => {
            if (requestTierList.id === 0) {
              return <CharacterPool key={requestTierList.id} id={requestTierList.id} characters={requestTierList.characters} />
            }
            return null
          })}
        </div>

        <DragOverlay style={{ transformOrigin: "0 0 " }}>
          {activeCharacter ? <Character id={activeCharacter.id} characterName={activeCharacter.characterName} /> : null}
        </DragOverlay>
      </DndContext>
    </>
  )
}