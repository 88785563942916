import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import { useDroppable } from "@dnd-kit/core";
import { Character } from "../Character";

export const CharacterPool = ({ id, characters }) => {
  const { setNodeRef } = useDroppable({ id: id })
  return (
    <SortableContext id={id} items={characters} strategy={rectSortingStrategy}>
      <div style={{ flex: "block", maxWidth: "800px", width: "100%", borderRadius: "10px", overflow: "hidden", margin: "10px auto" }}>
        <p style={{ background: "rgb(60, 60, 60)", flex: "flex", color: "white", fontSize: "16px", fontWeight: "bold", textAlign: "center", paddingTop: "10px" }}>ファイタープール</p>
        <div
          ref={setNodeRef}
          style={{
            background: "rgb(60, 60, 60)",
            textAlign: "center",
            maxWidth: "800px",
            display: "flex",
          }}>
          <div style={{
            display: "grid",
            gridTemplateColumns: `repeat(auto-fill, minmax(50px,1fr))`,
            gridGap: 1,
            width: "100%",
            minHeight: "53px",
            margin: "20px",
            marginTop: "10px"
          }}>
            {characters.map((character) => (
              <Character key={character.id} id={character.id} characterName={character.characterName}></Character>
            ))}
          </div>
        </div>
      </div>
    </SortableContext>
  )

}