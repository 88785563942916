import { CharacterImg } from "../../../home/CharacterImg"

export const RequestTierList = ({ request }) => {
  return (
    <>
      <div id={request.id} items={request.characters} >
        <div style={{ flex: "block", maxWidth: "800px", width: "100%", borderRadius: "10px", overflow: "hidden", margin: "10px auto" }}>
          <div style={{ position: "relative", width: "100%", height: "30px" }}>
            <img src="images/ティアバー.png" alt="ティアバー" style={{ width: "100%", height: "100%", objectFit: "fill" }} />
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
              {request.text ? <p style={{ color: "white", fontSize: "16px", fontWeight: "bold", marginLeft: "20px" }}>{request.text}</p> : <p></p>}
            </div>
          </div>
          <div style={{
            width: "100%",
            background: "rgb(60, 60, 60)",
            textAlign: "center",
            verticalAlign: "middle",
            display: "flex"
          }}>
            <div style={{
              display: "grid",
              gridTemplateColumns: `repeat(auto-fill, minmax(50px,1fr))`,
              gridGap: 1,
              width: "100%",
              minHeight: "53px",
              margin: "10px 10px",
            }}>
              {request.characters.map((character) => (
                <CharacterImg characterName={character.characterName}></CharacterImg>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}